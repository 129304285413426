import {
  Inject,
  NgZone,
  AfterViewInit,
  OnDestroy,
  Component,
  Input,
} from '@angular/core';
import { MapPlanBase } from './map-plan-base.model';
import { AgencyService, BuildingFloor, Property } from 'api';
import { ListTrackingService } from '../utils/list-tracking.service';

@Component({
  selector: 'app-map-plan-building-floor-base',
  templateUrl: './map-plan-building-floor-base.component.html',
})
export class MapPlanBuildingFloorBaseComponent
  extends MapPlanBase
  implements AfterViewInit, OnDestroy
{
  @Input() buildingFloor: BuildingFloor;
  @Input() propertyToolTipText: (property: Property) => string;

  constructor(
    private agencyService: AgencyService,
    // private localeService: LocaleService,
    public zone: NgZone,
    @Inject('WINDOW') public window: any,
    public listTrackingService: ListTrackingService
  ) {
    super(zone, window);
  }

  ngAfterViewInit(): void {
    this.runImgMappingScript();
  }

  ngOnDestroy(): void {
    this.removeImgMappingScript();
  }

  propertyToolTip(property: Property): string {
    if (this.propertyToolTipText) return this.propertyToolTipText(property);

    let copy = property.title + ' [ap: ' + property.number + ']';
    const availability = this.propertyAvailability(property.availability);
    if (availability) {
      copy += '<br>(' + this.propertyAvailability(property.availability) + ')';
    }

    return copy;
  }

  propertyAvailability(availability: string): string {
    if (this.canSeeAvailability()) {
      if (availability === 'available') {
        return 'Disponibil';
      }
      return 'Indisponibil';
    } else {
      return;
    }
  }

  canSeeAvailability(): boolean {
    return (
      !this.agencyService.agency.exposed_2d_sections ||
      this.agencyService.agency.exposed_2d_sections?.includes('availability')
    );
  }

  getHref(property: Property): string {
    return this.agencyService.agency.uuid + '/2d/' + property.friendly_id;
  }
}
