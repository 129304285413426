<main>
  <header
    *ngIf="offer.agent"
    class="custom-container no-page-break contact-agent flex flex-col md:flex-row gap-2.5 justify-between print:flex-row"
  >
    <div class="md:hidden lg:block print:hidden">
      <h2 class="inline-block">Contact agent</h2>
    </div>
    <div
      class="flex flex-row items-center gap-2.5"
      *ngIf="offer.agent.full_name"
    >
      <mat-icon>person</mat-icon>
      <div>
        {{ offer.agent.full_name }}
      </div>
    </div>
    <div *ngIf="offer.agent.phone" class="flex flex-row items-center gap-2.5">
      <mat-icon>phone</mat-icon>
      <div>
        <a class="block" [href]="'tel:' + offer.agent.phone">
          {{ offer.agent.phone | beautify }}
        </a>
        <a class="block" [href]="'tel:' + offer.agent.secondary_phone">
          {{ offer.agent.secondary_phone | beautify }}
        </a>
      </div>
    </div>
    <a
      *ngIf="offer.agent.email"
      class="flex flex-row items-center gap-2.5"
      [href]="'mailto:' + offer.agent.email"
      target="_blank"
    >
      <mat-icon>email</mat-icon>
      <div>
        {{ offer.agent.email }}
      </div>
    </a>
  </header>

  <div
    *ngIf="offer.message"
    class="message custom-container"
  >{{ offer.message }}</div>

  <div
    *ngIf="
      agencyService.agency.account_type == 'developer' &&
      agencyOffer.offer_project_description_html
    "
    class="custom-container agency-offer page-break-after"
  >
    <div
      class="rte"
      [innerHTML]="agencyOffer.offer_project_description_html"
    ></div>

    <div
      *ngIf="agencyOffer.offer_images && agencyOffer.offer_images.length"
      class="no-page-break"
    >
      <app-images-carousel-base [images]="agencyOffer.offer_images">
      </app-images-carousel-base>
    </div>
  </div>

  <hr class="custom-container" />

  <div *ngIf="!offer.exposed_property_fields.includes('project')">
    <div
      *ngFor="let offer_property of offer.offers_properties; let last = last"
      [ngClass]="{ 'page-break-after': !last }"
    >
      <app-house-details
        [promoPrice]="offer_property.offer_promo_price"
        [property]="offer_property.property"
        [withLike]="true"
        [likeDislike]="offer_property.status"
        (feedback)="sendFeedback(offer_property, $event)"
      ></app-house-details>
    </div>
  </div>
  <div *ngIf="offer.exposed_property_fields.includes('project')">
    <div
      *ngFor="let project of projects;"
    >
      <div class="custom-container agency-offer page-break-after">
        <h1 class="text-lg text-center md:text-4xl mb-7">
          {{ project.name }}
        </h1>

        <div
          class="rte whitespace-pre-wrap"
          [innerHTML]="project.description"
        ></div>

        <div
          *ngIf="project.images?.length"
          class="no-page-break mt-5"
        >
          <app-images-carousel-base [images]="project.images">
          </app-images-carousel-base>
        </div>
      </div>

      <div
        *ngFor="let offer_property of getPropertiesOfProjectId(project.id); let last = last"
        [ngClass]="{ 'page-break-after': !last }"
      >
        <app-house-details
          [promoPrice]="offer_property.offer_promo_price"
          [property]="offer_property.property"
          [withLike]="true"
          [likeDislike]="offer_property.status"
          (feedback)="sendFeedback(offer_property, $event)"
        ></app-house-details>
      </div>
    </div>
  </div>
</main>
